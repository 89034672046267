import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: var(--md);

  ${media.tablet`
    grid-template-columns: 1fr 2fr;
  `}

  h3 {
    font-size: ${props => props.lowercase ? "var(--copyMedium)" : "var(--copyNormal)"};
    text-transform: ${props => props.lowercase ? null : "uppercase"};
    letter-spacing: ${props => props.lowercase ? null : "1.4px"};
    margin-bottom: var(--sm);
  }

  h4 {
    font-size: var(--copyBase);
    font-family: var(--body);
    font-weight: 700;
  }

  p {
    margin-bottom: var(--sm);

    a {
      text-decoration: underline;
      transition: color .2s;

      :hover {
        color: var(--tertiary);
      }
    }
  }

  ul {
    margin-bottom: var(--sm);
    margin-left: var(--xs);
    list-style: disc inside none;
  }
`
