import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Container } from './copySplit.css'

const CopySplit = ({ children, lowercase }) => (
  <Container lowercase={lowercase}>
    <Fade bottom delay={150} duration={1250} distance="30px">
      {children}
    </Fade>
  </Container>
)

CopySplit.propTypes = {
  children: PropTypes.node.isRequired,
  lowercase: PropTypes.bool,
}

export default CopySplit
