import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  width: 100%;
  color: ${props => (props.bgGrad ? 'var(--white);' : null)};
  background: ${props => (props.bgTint ? 'var(--offWhite)' : null)};
  background-image: ${props => (props.bgGrad ? 'var(--greenGrad);' : null)};
  padding-top: ${props => (props.bgTint || props.bgGrad || props.borderTop ? 'var(--lg)' : null)};
  padding-bottom: ${props => (props.bgTint ? 'var(--sm)' : '1px')};
  margin-top: ${props => (props.topMargin ? 'var(--xl)' : null)};
  margin-bottom: ${props => (props.bgTint || props.bgGrad ? '0' : 'var(--sm)')};
  border-top: ${props => (props.borderTop ? '1px solid #eee' : null)};

  ${media.desktop`
    padding-top: ${props => (props.bgTint || props.bgGrad || props.borderTop ? 'var(--xl)' : null)};
  `}
`
export const Container = styled.div`
  max-width: 1128px;
  padding: 0 ${props => (props.noPadding ? '0' : 'var(--md)')};
  margin: 0 auto var(--lg);

  ${media.desktop`
    margin-bottom: var(--xl);
  `}
`
