import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import { graphql } from 'gatsby'

const CookiesPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.cookiesJson.title}</title>
      <meta name="description" content={data.cookiesJson.description} />
    </Helmet>
    <Intro topMargin headingLevel="h1" intro={data.cookiesJson.intro} />
    <CopyBlock topMargin>
      <CopySplit>
        <h3>Why we use cookies</h3>
        <div>
          <p>Cookies help us:</p>
          <ul>
            <li>Make our website work as you’d expect</li>
            <li>Remember your settings during and between visits</li>
            <li>Improve the speed/security of the site</li>
            <li>Continuously improve our website for you</li>
          </ul>
          <p>We do not use cookies to:</p>
          <ul>
            <li>Collect any personally identifiable information (without your express permission)</li>
            <li>Collect any sensitive information (without your express permission)</li>
            <li>Pass data to advertising networks</li>
            <li>Pass personally identifiable data to third parties</li>
            <li>Pay sales commissions</li>
          </ul>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Granting us permission to use cookies</h3>
        <p>
          If the settings on your software that you are using to view this website (your browser) are adjusted to accept
          cookies we take this, and your continued use of our website, to mean that you are fine with this. Should you
          wish to remove or not use cookies from our site you can learn how to do this below, however doing so will
          likely mean that our site will not work as you would expect.
        </p>
      </CopySplit>
      <CopySplit>
        <h3>Website Function Cookies</h3>
        <div>
          <h4>Persistent cookies</h4>
          <p>
            These cookies remain on a user’s device for the period of time specified in the cookie. They are activated
            each time that the user visits the website that created that particular cookie.
          </p>

          <h4>Session cookies</h4>
          <p>
            These cookies allow website operators to link the actions of a user during a browser session. A browser
            session starts when a user opens the browser window and finishes when they close the browser window. Session
            cookies are created temporarily. Once you close the browser, all session cookies are deleted.
          </p>
          <p>
            Cookies do lots of different jobs, like letting you navigate between pages efficiently, remembering your
            preferences, and generally improve the user experience.
          </p>
          <p>
            You can find more information about cookies at{' '}
            <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
              www.allaboutcookies.org
            </a>
            .
          </p>

          <h4>Our own cookies</h4>
          <p>
            We use cookies to make our website work including remembering your search settings. There is no way to
            prevent these cookies being set other than to not use our site.
          </p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Third party functions</h3>
        <div>
          <p>
            Our site, like most websites, includes functionality provided by third parties. A common example is an
            embedded YouTube video. Our site includes the following which use cookies:
          </p>
          <ul>
            <li>Google Analytics</li>
            <li>Google reCaptcha</li>
            <li>YouTube</li>
          </ul>
          <p>Disabling these cookies will likely break the functions offered by these third parties</p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Google Analytics</h3>
        <div>
          <p>
            We use these cookies to compile visitor statistics such as how many people have visited our website, what
            type of technology they are using (e.g. Mac or Windows which helps to identify when our site isn’t working
            as it should for particular technologies), how long they spend on the site, what page they look at etc. This
            helps us to continuously improve our website. These analytics also tell us if, on an anonymous basis, how
            people reached this site (e.g. from a search engine) and whether they have been here before helping us to
            put more money into developing our services for you instead of marketing spend.
          </p>
          <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
          <p>Tracking data is processed by google analytics. Please see their privacy policy for more information.</p>
          <p>
            You can opt out of Google Analytics cookies{' '}
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Turning Cookies Off</h3>
        <p>
          You can usually switch cookies off by adjusting your browser settings to stop it from accepting cookies (Learn
          how{' '}
          <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
            here
          </a>
          ). Doing so however will likely limit the functionality of our’s and a large proportion of the world’s
          websites as cookies are a standard part of most modern websites.
        </p>
      </CopySplit>
    </CopyBlock>
  </Layout>
)

CookiesPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default CookiesPage

export const query = graphql`
  query CookiesPageQuery {
    cookiesJson {
      title
      description
      intro {
        heading
        copy
      }
    }
  }
`
