import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container } from './copyBlock.css'

const CopyBlock = ({ children, id, bgTint, bgGrad, noPadding, borderTop, topMargin }) => (
  <Section bgTint={bgTint} bgGrad={bgGrad} topMargin={topMargin} id={id} borderTop={borderTop}>
    <Container noPadding={noPadding}>
      {children}
    </Container>
  </Section>
)

CopyBlock.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  bgTint: PropTypes.bool,
  bgGrad: PropTypes.bool,
  noPadding: PropTypes.bool,
  topMargin: PropTypes.bool,
}

export default CopyBlock
